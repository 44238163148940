<script setup lang="ts">
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useLocalization } from '/~/composables/localization'
import { useUI } from '/~/composables/ui'
import TermsConditions from '/~/views/terms/terms-conditions.vue'

const props = defineProps<{
  back?: 'close'
}>()

const { hideEwalletMenu } = useUI()
const { translate } = useLocalization()

const onBack = props.back === 'close' ? hideEwalletMenu : undefined
</script>

<template>
  <base-aside-page
    :title="`Terms ${translate('common.and')} Conditions`"
    no-padding
    class="bg-white"
    :back="onBack"
  >
    <terms-conditions class="px-5 pb-5" />
  </base-aside-page>
</template>
